import { configureStore } from "@reduxjs/toolkit";
import { oktaUserSlice } from "./oktaUser";

export function constructStore() {
  return configureStore({
    reducer: oktaUserSlice.reducer,
  });
}

export const oktaUserStore = constructStore();
