export function setRequestedPath() {
  if (!location.pathname || location.pathname == "/") {
    return;
  }
  localStorage.setItem(
    "requestedPath",
    `${location.pathname}${location.search}${location.hash || ""}`
  );
}

export function getRequestedPath(): string {
  return localStorage.getItem("requestedPath");
}

export function removeRequestedPath() {
  localStorage.removeItem("requestedPath");
}
