import { ApplicationConfiguration } from "../configurations/applicationConfiguration";
import { constructApplicationsWithConfig } from "./constructApplications";
import { attachCustomProps } from "./constructApplications/attachCustomProps";

export function getApplications(
  applicationConfigurations: ApplicationConfiguration[]
) {
  const applications = constructApplicationsWithConfig(
    applicationConfigurations
  );
  applications.forEach((application) => attachCustomProps(application));
  return applications;
}
