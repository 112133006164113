import Cookies, { Cookie } from "universal-cookie";

const cookies = new Cookies();

export function getCookie(name: string) {
  return cookies.get(name, { doNotParse: false });
}

export function setCookie(name: string, value: Cookie): Cookie {
  cookies.set(name, value, {
    path: "/",
    secure: true,
    sameSite: "strict",
  });
}
