import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationConfiguration } from "../../../configurations/applicationConfiguration";
import { orderSortedApplication } from "../../../application/applicationConfigurations/orderSortedApplications";

type ApplicationConfigState = {
  activeApplications: ApplicationConfiguration[];
};

const initialApplicationConfigState: ApplicationConfigState = {
  activeApplications: [],
};

export const applicationConfigSlice = createSlice({
  name: "applicationConfig",
  initialState: initialApplicationConfigState,
  reducers: {
    addActiveApplication: function (
      state: ApplicationConfigState,
      action: PayloadAction<ApplicationConfiguration>
    ) {
      if (action.payload) state.activeApplications.push(action.payload);
      orderSortedApplication(state.activeApplications);
    },
  },
});
