import { UserClaims } from "@okta/okta-auth-js/lib/types/UserClaims";
import { oktaUserStore } from "../../store/oktaUserStore";

export async function getCurrentUser(): Promise<UserClaims> {
  return new Promise(waitForOktaUserStatePromiseResolver);
}

function waitForOktaUserStatePromiseResolver(resolve) {
  const intervalId = setInterval(() => {
    if (oktaUserStore.getState().data != null) {
      clearInterval(intervalId);
      resolve(oktaUserStore.getState().data);
    }
  }, 10);
}
