import { UserClaims } from "@okta/okta-auth-js/lib/types/UserClaims";
import { isAllowedUser } from "../../../configurations/internalTeam";
import { RegisterApplicationConfig } from "../../constructApplications";
import { User } from "@one-profile/core";
import { getCurrentUser } from "../../../authentication/currentUser";
import { getAccessToken } from "../../../authentication/accessToken";

export async function resolveActivation(
  application: RegisterApplicationConfig,
  user: User
): Promise<boolean> {
  if (application.name == "@one-profile/not-found") {
    return true;
  }
  const currentUser = await getCurrentUser();
  return System.import(application.name)
    .then((module) => {
      const claimedUser: UserClaims & User = {
        ...currentUser,
        ...user,
      } as UserClaims & User;
      return moduleActivationRequest(module, claimedUser)
        .then(
          (isActive: boolean) =>
            isActive || isAllowedUser(claimedUser.employeeNumber)
        )
        .catch(() => isAllowedUser(claimedUser.employeeNumber));
    })
    .catch((error) => {
      console.debug("Error during module loading " + error);
      return false;
    });
}

async function moduleActivationRequest(
  module: System.Module,
  currentUser: UserClaims & User
): Promise<boolean> {
  if (module?.config?.activationFn) {
    return module.config
      .activationFn(await getAccessToken(), currentUser)
      .then((result) => {
        return result;
      });
  } else {
    return true;
  }
}
