import { ApplicationConfiguration } from "../../../../../configurations/applicationConfiguration";

export function getApplicationRoutes(
  applicationConfigurations: ApplicationConfiguration[]
) {
  return applicationConfigurations.map((applicationConfiguration) => {
    return {
      routes: [{ type: "application", name: applicationConfiguration.name }],
      type: "route",
      path: applicationConfiguration.route.path,
      exact: applicationConfiguration.route.exact,
    };
  });
}
