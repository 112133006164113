export function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/sw.js", {
        scope: "/",
      })
      .then((registration) => {
        if (registration.installing) {
          console.debug("Service worker installing");
        } else if (registration.waiting) {
          console.debug("Service worker installed");
        } else if (registration.active) {
          console.debug("Service worker active");
        }
      })
      .catch((err) => console.error(`Registration failed with ${err}`));
  }
}
