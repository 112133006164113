import { oktaClient } from "../oktaClient";
import { IsAuthenticatedOptions } from "@okta/okta-auth-js/lib/types";
import { oktaUserStore } from "../../store/oktaUserStore";
import { fetchOktaUserThunk } from "../../store/oktaUserStore/oktaUser";

const isAuthenticatedOptions: IsAuthenticatedOptions = {
  onExpiredToken: "renew",
};

export function isAuthenticated(): Promise<boolean> {
  if (
    oktaClient.getAccessToken() &&
    oktaClient.tokenManager.hasExpired(oktaClient.getAccessToken())
  ) {
    return oktaClient
      .isAuthenticated(isAuthenticatedOptions)
      .then((isAuthenticated) => {
        oktaUserStore.dispatch(fetchOktaUserThunk());
        return isAuthenticated;
      });
  }
  return oktaClient.isAuthenticated(isAuthenticatedOptions);
}
