import { users } from "@one-profile/core";
import {
  getImportMapEnv,
  getMatomoContainerId,
  getMatomoSiteId,
} from "../../configurations/globals";
import { getAccessToken } from "../../authentication/accessToken";
import { getCurrentUser } from "../../authentication/currentUser";

const allowedEnvironments = ["stage", "prod", "dev"];
const trackingUrl = "https://thoughtworks.innocraft.cloud/";
const containerUrl = `https://thoughtworks.innocraft.cloud/js/container_${getMatomoContainerId()}.js`;

export async function setupMatomo() {
  users
    .getMe(await getAccessToken())
    .then((user) => {
      initMyTWContainer();
      initMatomo(user);
    })
    .catch(() => {
      getCurrentUser().then((user) => initMatomo(user));
    });
}

function initMyTWContainer() {
  if (allowedEnvironments.includes(getImportMapEnv())) {
    preContainerSetup();
    insertContainerScript();
  }
}

function preContainerSetup() {
  const _mtm = (global._mtm = global._mtm || []);
  _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
}

function insertContainerScript() {
  const scriptElement = document.createElement("script"),
    firstScriptElement = document.getElementsByTagName("script")[0];
  scriptElement.src = containerUrl;
  scriptElement.async = true;
  firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
}

function initMatomo(user) {
  if (allowedEnvironments.includes(getImportMapEnv())) {
    preTrackingSetup(user);
    trackingSetup();
    insertTrackingScript();
  }
}

function preTrackingSetup(user) {
  const _paq = (global._paq = global._paq || []);
  _paq.push(["setUserId", user.email]);
  _paq.push(["setCustomDimension", 1, getRole(user)]);
  _paq.push(["setCustomDimension", 2, getDepartment(user)]);
  _paq.push(["setCustomDimension", 3, getLocation(user)]);
}

function trackingSetup() {
  const _paq = (global._paq = global._paq || []);
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);
  _paq.push(["setTrackerUrl", trackingUrl + "matomo.php"]);
  _paq.push(["setSiteId", getMatomoSiteId()]);

  window.addEventListener("popstate", function () {
    const _paq = (global._paq = global._paq || []);
    const path = document.location.pathname.replace("/", "");
    _paq.push(["setCustomUrl", "/" + path]);
    _paq.push(["setDocumentTitle", path]);
    _paq.push(["trackPageView"]);
  });
}

function insertTrackingScript() {
  const scriptElement = document.createElement("script"),
    firstScriptElement = document.getElementsByTagName("script")[0];
  scriptElement.async = true;
  scriptElement.src = trackingUrl + "matomo.js";
  firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
}

function getRole(user) {
  return user.role ? user.role.name : "";
}

function getDepartment(user) {
  return user.department ? user.department.id : "";
}

function getLocation(user) {
  return user.homeOffice ? user.homeOffice.countryIsoCode : "";
}
