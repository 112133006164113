import { RegisterApplicationConfig } from "../../application/constructApplications";
import { User } from "@one-profile/core";
import { registerActivatedApplication } from "../registerActivatedApplication";

export function registerActivatedApplications(
  applications: Array<RegisterApplicationConfig>,
  user: User
) {
  return Promise.all(
    applications.map((application) =>
      registerActivatedApplication(application, user)
    )
  );
}
