import { getPostLoginRedirectionPath } from "./postLoginRedirectionPath";
import {
  isPostLoginRedirectionComplete,
  setPostLoginRedirectionComplete,
} from "./isPostLoginRedirectionComplete";

export function handlePostLoginRedirection(result: boolean): Promise<boolean> {
  if (result && shouldRedirectPostLogin()) {
    redirectPostLogin();
    return Promise.resolve(result);
  }
  setPostLoginRedirectionComplete(result);
  return Promise.resolve(result);
}

function redirectPostLogin(): void {
  setPostLoginRedirectionComplete(true);
  const redirectPath = getPostLoginRedirectionPath();
  window.location.pathname = redirectPath == undefined ? "/" : redirectPath;
}

function shouldRedirectPostLogin(): boolean {
  return !isPostLoginRedirectionComplete();
}
