import { authenticate } from "../";
import { setPostLoginRedirectionPath } from "./handlePostLoginRedirection/postLoginRedirectionPath";
import { handlePostLoginRedirection } from "./handlePostLoginRedirection";

export function decorateRedirectionHandler(
  authenticationPromise: typeof authenticate
): Promise<boolean> {
  return setPostLoginRedirectionPath()
    .then(authenticationPromise)
    .then(handlePostLoginRedirection);
}
