import { ApplicationConfiguration } from "../../configurations/applicationConfiguration";
import { constructLayoutEngine } from "single-spa-layout";
import { constructRoutesFor } from "../routes";
import { getApplications } from "../";

export function activateLayoutEngine(
  applicationConfigurations: ApplicationConfiguration[]
) {
  const layoutEngine = getLayoutEngine(applicationConfigurations);
  layoutEngine.activate();
  return layoutEngine;
}

function getLayoutEngine(
  applicationConfigurations: ApplicationConfiguration[]
) {
  return constructLayoutEngine({
    routes: constructRoutesFor(applicationConfigurations),
    applications: getApplications(applicationConfigurations),
  });
}
