import { ApplicationConfiguration } from "../../configurations/applicationConfiguration";
import { constructApplications } from "single-spa-layout";
import { pages } from "@one-profile/core";
import singleSpa, { Application } from "single-spa";
import { WithLoadFunction } from "single-spa-layout/dist/types/browser/constructApplications";
import { constructRoutesFor } from "../routes";

export type RegisterApplicationConfig = singleSpa.RegisterApplicationConfig &
  WithLoadFunction;

export function constructApplicationsWithConfig(
  applicationConfigurations: ApplicationConfiguration[]
): Array<RegisterApplicationConfig> {
  return constructApplications({
    routes: constructRoutesFor(applicationConfigurations),
    async loadApp({ name }): Promise<Application> {
      if (name == "@one-profile/not-found") {
        return pages.NotFound;
      }
      return System.import(name);
    },
  });
}
