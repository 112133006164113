import { getCookie, setCookie } from "../../../../configurations/cookies";
import { isPostLoginRedirectionStarted } from "../isPostLoginRedirectionComplete";

export const COOKIE_REDIRECT_PATH = applicationName + "-redirect-path";

export async function setPostLoginRedirectionPath(): Promise<void> {
  if (shouldSavePostLoginRedirectionPath()) {
    setCookie(COOKIE_REDIRECT_PATH, window.location.pathname);
  }
}

function shouldSavePostLoginRedirectionPath(): boolean {
  return !isPostLoginRedirectionStarted();
}

export function getPostLoginRedirectionPath() {
  return getCookie(COOKIE_REDIRECT_PATH);
}
