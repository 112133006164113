import { ApplicationConfiguration } from "../../configurations/applicationConfiguration";
import { getSubRoutes } from "./subRoutes";
import { getApplicationRedirects } from "./redirects";
import { constructRoutes } from "single-spa-layout";

export function constructRoutesFor(
  applicationConfigurations: ApplicationConfiguration[]
) {
  return constructRoutes(getRoutes(applicationConfigurations));
}

function getRoutes(applicationConfigurations: ApplicationConfiguration[]) {
  return {
    base: "/",
    containerEl: "body",
    mode: "history",
    routes: getSubRoutes(applicationConfigurations),
    redirects: getApplicationRedirects(applicationConfigurations),
  };
}
