import theme from "../../../configurations/theme";
import { applicationStore } from "../../../store/applicationStore";
import { getCurrentUser } from "../../../authentication/currentUser";
import { signOut } from "../../../authentication/signOut";
import { getAccessToken } from "../../../authentication/accessToken";

export function attachCustomProps(application) {
  application.customProps = {
    getAccessToken: getAccessToken,
    getCurrentUser: getCurrentUser,
    theme,
  };
  attachNavigationProps(application);
}

function attachNavigationProps(application) {
  if (application.name === "@one-profile/navbar") {
    application.customProps = {
      ...application.customProps,
      signOut: signOut,
      applicationConfigurations: applicationStore.getState().activeApplications,
      getActiveApplications: () =>
        applicationStore.getState().activeApplications,
      subscribeToAppActivation: applicationStore.subscribe,
    };
  }
}
