export function navigationSubRoute() {
  return {
    type: "nav",
    routes: [
      {
        type: "application",
        name: "@one-profile/navbar",
      },
    ],
  };
}
