import { getCookie, setCookie } from "../../../../configurations/cookies";

export const COOKIE_POST_LOGIN_REDIRECTION_COMPLETE =
  applicationName + "-redirection-complete";

export function isPostLoginRedirectionComplete(): boolean {
  return getCookie(COOKIE_POST_LOGIN_REDIRECTION_COMPLETE);
}

export function isPostLoginRedirectionStarted(): boolean {
  return getCookie(COOKIE_POST_LOGIN_REDIRECTION_COMPLETE) ? true : false;
}

export function setPostLoginRedirectionComplete(
  isRedirectionComplete: boolean
): void {
  setCookie(COOKIE_POST_LOGIN_REDIRECTION_COMPLETE, isRedirectionComplete);
}
