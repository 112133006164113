export function getOauthIssuer() {
  return (getWindow().oauth2Issuer = oauth2Issuer);
}

export function getOauthClientId() {
  return (getWindow().oauthClientId = oauthClientId);
}

export function getImportMapEnv() {
  return (getWindow().importMapEnv = importMapEnv);
}

export function getMatomoSiteId() {
  return (getWindow().matomoSiteId = matomoSiteId);
}

export function getFeatureToggle() {
  return (getWindow().featureToggle = featureToggle);
}

export function getMatomoContainerId() {
  return (getWindow().matomoContainerId = matomoContainerId);
}

export function getEnabledFeatures(): string[] {
  return (getWindow().enabledFeatures = enabledFeatures).split(",");
}

function getWindow() {
  return window as Window as CustomWindow;
}
