import { oktaClient } from "./oktaClient";
import { isAuthenticated } from "./isAuthenticated";
import { handleLoginRedirection } from "./oktaLogin/handleLoginRedirection";
import { oktaLogin } from "./oktaLogin";
import { setRequestedPath } from "./requestedPath";

export function authenticate() {
  if (oktaClient.isLoginRedirect()) {
    return handleLoginRedirection();
  }
  return isAuthenticated().then((isAuthenticated) => {
    if (!isAuthenticated) {
      setRequestedPath();
      return oktaLogin();
    }
    return isAuthenticated;
  });
}
