export const isAllowedUser = (employeeId) => {
  const allIds = kmTeamIds.concat(catsTeamIds);
  return allIds.includes(employeeId);
};

const kmTeamIds = [
  "15710",
  "34299",
  "27769",
  "33952",
  "27705",
  "31435",
  "26986",
  "31244",
  "31291",
  "21356",
  "20621",
  "17125",
  "32126",
  "28984",
  "36951",
];

const catsTeamIds = [
  "27763",
  "10407",
  "26532",
  "31467",
  "35535",
  "26002",
  "38816",
];
