import { bootstrap } from "./bootstrap";
import { setupMatomo } from "./services/matomo";
import { initQualaroo } from "./services/qualaroo";
import { authenticate } from "./authentication";
import { oktaUserStore } from "./store/oktaUserStore";
import { fetchOktaUserThunk } from "./store/oktaUserStore/oktaUser";
import { decorateRedirectionHandler } from "./authentication/postLoginRedirection";
import { removeRequestedPath } from "./authentication/requestedPath";
import { registerServiceWorker } from "./register-service-worker";

registerServiceWorker();
decorateRedirectionHandler(authenticate).then((isAuthenticated) => {
  if (isAuthenticated) {
    oktaUserStore.dispatch(fetchOktaUserThunk());
    bootstrap().then(setupMatomo).then(initQualaroo).then(removeRequestedPath);
  }
});
