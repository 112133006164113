import { ApplicationConfiguration } from "../../../configurations/applicationConfiguration";
import { applicationsSubRoute } from "./application";
import { introSubRoute } from "./intro";
import { navigationSubRoute } from "./navigation";

export function getSubRoutes(
  applicationConfigurations: ApplicationConfiguration[]
) {
  return [
    navigationSubRoute(),
    introSubRoute(),
    applicationsSubRoute(applicationConfigurations),
  ];
}
