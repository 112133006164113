import {
  ApplicationConfiguration,
  getEnabledApplicationConfigurations,
} from "../../configurations/applicationConfiguration";
import { orderSortedApplication } from "./orderSortedApplications";
import { RegisterApplicationConfig } from "../constructApplications";

export function getApplicationConfigurations() {
  return orderSortedApplication(getEnabledApplicationConfigurations());
}

export function getApplicationConfigurationBy(
  application: RegisterApplicationConfig
): ApplicationConfiguration | undefined {
  return getEnabledApplicationConfigurations().find(
    (config) => config.name == application.name
  );
}
