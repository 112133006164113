import { ApplicationConfiguration } from "../../../../configurations/applicationConfiguration";
import { getApplicationRoutes } from "./applicationRoute";
import { getPageNotFoundRoute } from "./pageNotFoundRoute";

export function applicationsSubRoute(
  applicationConfigurations: ApplicationConfiguration[]
) {
  return {
    type: "main",
    attrs: [
      {
        name: "class",
        value: "mytw-main-container",
      },
    ],
    routes: [
      ...getApplicationRoutes(applicationConfigurations),
      getPageNotFoundRoute(),
    ],
  };
}
