import { start } from "single-spa";
import { disableLoader } from "../services/spinner";
import { applicationStore } from "../store/applicationStore";
import { getApplicationConfigurations } from "../application/applicationConfigurations";
import { users } from "@one-profile/core";
import { getApplications } from "../application";
import { getAccessToken } from "../authentication/accessToken";
import { activateLayoutEngine } from "../application/layoutEngine";
import { registerActivatedApplications } from "./registerActivatedApplications";

export async function bootstrap() {
  return registerActivatedApplications(
    getApplications(getApplicationConfigurations()),
    await users.getMe(await getAccessToken())
  ).then(startApplications);
}

function startApplications() {
  activateLayoutEngine(applicationStore.getState().activeApplications);
  start();
  disableLoader();
}
