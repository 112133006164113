import { ApplicationConfiguration } from "../../../configurations/applicationConfiguration";
import { getRequestedPath } from "../../../authentication/requestedPath";

export function getApplicationRedirects(
  applicationConfigurations: ApplicationConfiguration[]
) {
  const redirectsApp = {};
  applicationConfigurations.forEach((applicationConfiguration) => {
    if (applicationConfiguration.redirectsRoutePaths) {
      applicationConfiguration.redirectsRoutePaths.forEach((redirect) => {
        redirectsApp[redirect] = applicationConfiguration.route.path;
      });
    }
  });
  if (applicationConfigurations.length > 0) {
    redirectsApp["/"] = getDefaultUrl(applicationConfigurations);
  } else {
    redirectsApp["/"] = "/profile";
  }
  return redirectsApp;
}

function getDefaultUrl(applicationConfigurations) {
  const requestedPath = getRequestedPath();
  if (requestedPath) {
    return requestedPath;
  }
  return applicationConfigurations[0].route.path;
}
