import { getImportMapEnv } from "../../configurations/globals";

export function initQualaroo() {
  if (["stage", "prod"].includes(getImportMapEnv())) {
    global._kiq = global._kiq || [];
    const firstScript = document.getElementsByTagName("script")[0],
      scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.async = true;
    scriptElement.src = "https://cl.qualaroo.com/ki.js/80068/iFR.js";
    firstScript.parentNode.insertBefore(scriptElement, firstScript);

    const identityScript = document.createElement("script");
    identityScript.type = "text/javascript";
    identityScript.text =
      "_kiq.push(['identity', JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.claims.sub])";
    firstScript.parentNode.appendChild(identityScript);
  }
}
